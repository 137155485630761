export const hero = {
  title: `The top provider of point-of-sale systems in San Diego, California`,
  subtext:
    "Whether you’re in “the box” of El Cajon, enjoying PB’s nightlife, or surfing with the best at Oceanside Pier or Imperial Beach, it’s clear San Diego offers bountiful opportunities for business owners to thrive in a large, tourist-friendly hub. But how does a business in the area properly manage itself and reach potential customers for continued growth? Local businesses are turning to SpotOn’s superior POS system in San Diego, designed for businesses just like yours.",
};

export const quoteWithVideo = {
  quote: `"When we first got all our staff on board, SpotOn definitely was unbelievable. They absolutely love it. It's been great having local support there in case they need it, as well."`,
  person: "Ryan Hood",
  location: "Beer Town, Oceanside, CA",
  thumbnailImageName: "san-diego-video-thumb.png",
  videoId: "9dqVJFmOQmA",
};

export const imageWithText = {
  heading: "POS systems to fit your business",
  paragraphs: [
    `In the past, a POS system was the old cash register that sat on a counter and was used to make and record a sale. It was a one-size-fits-all system.`,
    `In today’s economy, a point-of-sale system can be so much more. With an area the size of San Diego and the potential to reach customers visiting from around the world, you need a POS system as scalable and customizable as your business.`,
    `SpotOn’s POS systems are designed to fit your business needs. Whether in restaurants, retail,  local services, or enterprise-scale venues, the SpotOn POS can accept all types of payments in-store and online, track customer preferences, provide detailed purchase reports, help you communicate deals and promotions, and keep track of sales.`,
  ],
  imageName: "san-diego-image-1.png",
};

export const pros = {
  heading: `POS solutions in San Diego for restaurants, bars, retail, and enterprise businesses`,
  paragraphs: [
    `Running a business in San Diego, where there are many tourists with high expectations and a heavy grasp on technology, requires flexible payment options while also providing superior customer service.`,
    `Whether you’re running a bar or restaurant along University and 30th in North Park, at the World Famous Zoo, or in La Jolla, Coronado, or Mission Beach, you need a POS system that is capable of handling your specific needs, location, and clientele.`,
    `For businesses, restaurants, and bars in the Gaslamp and around Petco Park, it’s essential that your POS solution is mobile and fully customizable to your location to enhance your customer experience.`,
    `SpotOn’s POS system in San Diego, California, services the following industries:`,
  ],
  bullets: [
    `Restaurants, from fine dining to quick service`,
    `Bars, nightclubs, and breweries`,
    `Retail, including sporting goods, home decor, clothing, and apparel`,
    `Food trucks`,
    `Liquor stores`,
    `Professional services such as accountants `,
    `Local services such as auto`,
    `Enterprise businesses such as educational campuses, sports and entertainment, amusement and attractions, and more`,
  ],
  imageName: "san-diego-image-2.png",
};

export const mainFeature = {
  heading: `With SpotOn’s POS for restaurants and bars, you’re better equipped to  immediately take and send orders, get robust reporting (on sales, labor, etc.), and grow your marketing reach.`,
  imageName: "san-diego-image-3.png",
};

export const pros2 = {
  heading: `Here’s how the SpotOn POS system works in restaurants`,
  topText: "In real-time, you can manage and track the following:",
  bottomText: `All of these features make SpotOn’s <a href="/restaurant-pos" class="text-primary">restaurant POS</a> ideal for San Diego’s poolside bars, beachside cocktails, or any venue with outdoor space.`,
  bullets: [
    `Seated tables`,
    `Easy payment operations and secure payment processing`,
    `Most popular dishes and drink selections`,
    `Menu modifications on the fly`,
    `Item availability`,
    `Happy-hour discounts`,
    `Membership discounts, loyalty programs, and promotions`,
    `Remote and handheld order taking and payment options`,
  ],
  imageName: "san-diego-image-4.png",
};

export const footer = {
  heading: `If you’re looking for POS solutions in San Diego and want to learn more about what SpotOn’s POS system can do for your business, contact us today for a  free demo`,
  paragraphs: [
    `The benefit of using SpotOn’s POS system is that you have the power to manage your employees and track sales and customer habits, helping you to improve your offerings and increase sales. Better yet, you can use handheld devices to do many of your restaurant’s operational tasks, rather than just a traditional kiosk and PC.`,
    `This translates to having the ability to take payments anywhere, deliver your services and experience to scale, reduce costs, and grow revenues while reaching more customers—all from the power of SpotOn’s POS.`,
    `If you’re looking for POS solutions in San Diego and want to learn more about what SpotOn’s POS system can do for your business, contact us today for a <a href="/demo" class="text-primary">free demo</a>. Upon sign up, SpotOn provides new San Diego restaurants (and other select cities) with a free photoshoot through Snappr, a nationwide network of photographers that serve enterprise clients like Doordash, Toast, PopMenu, and Bentobox. <a href="https://spotonteam.atlassian.net/wiki/spaces/SPO/pages/2118483969/Snappr+Custom+Photography+-+Pilot" class="text-primary">More information on the Snappr & SpotOn partnership here.</a>`,
  ],
  cta: {
    target: "#form",
    title: "Learn more",
  },
};

export const terms = {
  title: "Snappr terms & conditions.",
  paragraphs: [
    `The Snappr promotion offered through SpotOn is only available to those customers purchasing for their own personal or their company's use in the cities of Atlanta, Georgia, San Francisco, California, and San Diego, California from 7/1/2022 to 12/31/22. These images produced during the promotion are intended for personal or company promotional use and not intended for any sort of resale. All promotions are non-transferrable. Promotional offers end at 11:59 PM on the expiration date unless indicated otherwise and are available only to U.S. residents, exclusively provided through the Snappr photography company.`,
    `Any discounts, prizes, or promotional considerations applicable to this order apply only to the initial purchase and purchase price. They are not applicable to other charges, including: previous purchases, current or existing monthly fees, unpaid balances, or any future charges related to the product(s).`,
  ],
};
